import React, { Component } from 'react'

export default class FormLoader extends Component {
    render() {
        return (
            <div className={this.props.inline ? "inline-loader":"table-loader"}>
                <div className="loader-container">
                    <span>
                        <i className={"fa fa-spinner fa-spin fa-fw "+(this.props.small ? "fa-2x":"fa-3x")}></i>
                    </span>
                </div>
            </div>
        )
    }
}
