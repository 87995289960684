// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import registerServiceWorker from './registerServiceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

import registerServiceWorker from './registerServiceWorker';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import { RpmOidc, initUserManager } from 'rug-widgets';

import { history, configureStore } from './shared/store/configureStore';

import App from './App';
import * as constants from './shared/constants/constants';

initUserManager(constants.AUTH_CONFIG);

const store = configureStore();

const rootElement = document.getElementById('root');
ReactDOM.render(
    <Provider store={store}>
        <RpmOidc store={store} history={history}
            redirectPath='/callback'
            callbackPageContent={<div>Redirecting...</div>}>
            <App />
        </RpmOidc>
    </Provider>,
    rootElement
);

registerServiceWorker();
