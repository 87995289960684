import React, { Component } from 'react';

import { RugAppsWidget } from 'rug-widgets';

import axios from './axios/axios-api';

import './scss/App.css';

import withErrorHandler from './hoc/withErrorHandler/withErrorHandler';

import { history } from "./shared/store/configureStore";

import { Route, Switch } from 'react-router-dom';

import Redirect from './pages/redirect';
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Notification from "./components/common/Notification";
import Dashboard from "./pages/dashboard";
import ApiCalls from "./pages/apiCall";

import * as constants from './shared/constants/constants';

class App extends Component {

  render() {
    const containerStyle = {
      minHeight: "901px"
    };

    return (
      <div className="App">
        <Navbar history={history} />
        <div className="page-container" style={containerStyle}>
          <div className="page-content">
            <div className="content">
              <Switch>
                <Route path="/" exact>
                  <Redirect to="/dashboard" />
                </Route>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/historyApiCalls" component={ApiCalls} />
                <Route path="/redirect" component={Redirect} />
              </Switch>
              {this.props.user ? <RugAppsWidget
                accessToken={this.props.user.access_token}
                spellbarConfigEndpointUrl={constants.SPELLBAR_CONFIG_ENDPOINT_URL}
                onBeforeRedirect={(event) => {
                  return true;
                }} /> : null}
              <Footer />
            </div>
          </div>
        </div>
        <Notification />
      </div>
    );
  }
}

export default withErrorHandler(App, axios);