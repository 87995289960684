import React from "react";
import Datetime from "react-datetime";
var moment = require('moment');

export default class DateRangeSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            selectedOption: 0,
            dates: [
                { value: 0, text: "None" },
                { value: 1, text: "Last month" },
                { value: 2, text: "Today" },
                { value: 3, text: "Custom" }
            ]
        };
    }

    handleOptionChange = (e) => {
        this.setState({ selectedOption: +e.target.value }, function() {
            if (this.state.selectedOption === 0) {
                this.handleDatesChange(moment.utc("0001-01-01"), moment.utc("0001-01-01"));
            }
            if (this.state.selectedOption === 1) {
                this.handleDatesChange(moment().local().add(-1, 'M'), moment().local());
            } else if (this.state.selectedOption === 2) {
                this.handleDatesChange(moment().local().add(-1, 'd'), moment().local());
            } else if (this.state.selectedOption === 3) {
                this.handleDatesChange(moment().startOf('date'), moment());
            }
        });
    };

    handleDatesChange = (from, to) => {
        this.setState({ from, to }, () => {
            this.props.onDatesChanged(from, to);
        });
    };

    render() {
        return (
            <div className="daterange-container">
                {this.props.title ? <label className="label-indent-right">{this.props.title}</label> : null }
                <select className="form-control"
                        style={{width: '90px'}}
                        value={this.state.selectedOption}
                        onChange={(e) => this.handleOptionChange(e)}>
                    {this.state.dates.map((item) => { return (<option key={item.value} value={item.value}>{item.text}</option>) })}
                </select>
                {this.state.selectedOption === 3 ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}}>
                            <div className="col-md-6">
                                <Datetime
                                    value={this.props.selectedFrom}
                                    onChange={(e) => this.handleDatesChange(e, this.state.to)} />
                            </div>
                            <div className="col-md-6" style={{paddingLeft: '5px'}}>
                                <Datetime
                                    value={this.props.selectedTo}
                                    onChange={(e) => this.handleDatesChange(this.state.from, e)} />
                            </div>
                        </div>
                    </div> : ""}
            </div>
        );
    }
}