import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import ApiCallsTable from "./components/ApiCallsTable";

export default class ApiCalls extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/historyApiCalls" component={ApiCallsTable}/>
            </Switch>
        )
    }
}