import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as notificationActions from '../../pages/notifications/actions/notificationActions';
import {ToastContainer} from "react-toastr";

class Notification extends Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps.notificationList && nextProps.notificationList.length > 0) {
            nextProps
                .notificationList
                .forEach((notification) => {
                    this.showNotifiction(notification);
                    this
                        .props
                        .notifications
                        .remove(notification.id);
                });
        }
    }

    showNotifiction = (notification) => {
        if (notification.type === "success") {
            this
                .toastContainer
                .success(notification.title, notification.description, {
                    timeOut: 2000,
                    extendedTimeOut: 1000
                });
        } else if (notification.type === "fail") {
            this
                .toastContainer
                .error(notification.title, notification.description, {
                    timeOut: 2000,
                    extendedTimeOut: 1000
                });
        }
    }

    render() {
        return (
            <div>
                <ToastContainer ref={ref => this.toastContainer = ref} className="toast-top-right"/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {notificationList: state.notification}
}

function mapDispatchToProps(dispatch) {
    return {
        notifications: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)