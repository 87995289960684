import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import ReactJson from "react-json-view";

export default class ExpandableTextModal extends Component {
    componentWillReceiveProps(nextProps) {
        if (nextProps.show && !this.props.show) {
            this.setState({});
        }
    }

    render() {
        let jsonObj = null;

        try {
            jsonObj = JSON.parse(this.props.text);
            if (typeof jsonObj !== "object")
                throw new Error('');
        } catch (e) {
            jsonObj = null;
        }

        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {jsonObj ? <ReactJson theme="monokai" src={jsonObj} /> : <textarea className="form-control" rows={10}>{this.props.text}</textarea>}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
