import React, {Component} from 'react'
import { WidthProvider, Responsive } from "react-grid-layout";
import ExternalApiCallsChart from "./widgets/ExternalApiCallsChart";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class DashboardMain extends Component {
    constructor(props) {
        super(props);

        let originalLayouts =  [];
        this.state = {
            layouts: JSON.parse(JSON.stringify(originalLayouts))
        };

        this.controls = [];
    }

    render() {
        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Dashboard</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <ResponsiveReactGridLayout
                    className="layout"
                    layouts={this.state.layouts}
                    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    rowHeight={35}
                    width={1200}
                    compactType={null}
                    draggableHandle='.panel-heading'
                    onLayoutChange={(layout, layouts) =>{}

                    }>
                    <div key="chartExample" className="panel panel-flat" data-grid={{ w: 6, h: 7, x: 0, y: 0, minW: 2, minH: 4 }}>
                        <ExternalApiCallsChart
                            ref={instance => { this.controls["ExternalApiCallsChart"] = instance; }} />
                    </div>
                </ResponsiveReactGridLayout>
            </div>
        )
    }
}