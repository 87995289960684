import * as actionTypes from '../constants/actionTypes';

export default(state = [], action) => {
    switch (action.type) {
        case actionTypes.ADD_NOTIFICATION:
            return [...state, action.payload];
        case actionTypes.REMOVE_NOTIFICATION:
            return [
                ...state.filter((x)=>x.id !== action.payload),
            ];
        default:
            return state;
    }
};