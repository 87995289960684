import React, {Component} from 'react';
import {Modal, Button} from "react-bootstrap";

export default class DeleteConfirmationModal extends Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.description}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="danger"
                        onClick={() => {
                        this
                            .props
                            .onConfirm();
                    }}>Delete</Button>
                    <Button
                        onClick={() => {
                        this
                            .props
                            .onClose()
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
