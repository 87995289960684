import React, { Component } from 'react';
import logo from '../img/logo-rpm-header2.png';
import UserWidget from "./UserWidget";

class Navbar extends Component {
    menuClick = (e, url) => {
        e.preventDefault();
        this.props.history.push(url);
    };

    render() {
        return (
            <div>
                <div className="navbar navbar-inverse">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/"><img src={logo} alt="" /></a>
                    </div>
                    <div className="navbar-collapse collapse">
                        <ul className="nav navbar-nav">
                            <li><a href="" onClick={(e) => this.menuClick(e, "/dashboard")}>Dashboard</a></li>
                            <li><a href="" onClick={(e) => this.menuClick(e, "/historyApiCalls")}>Api calls</a></li>
                            <li><a href="" onClick={(e) => this.menuClick(e, "/redirect")}>Redirects</a></li>
                        </ul>
                        <UserWidget {...this.props} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;