import React, {Component} from 'react'
import * as dashboardActions from "../../actions/dashboardActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ReactHighcharts from "react-highcharts";
import FormLoader from "../../../../components/common/FormLoader";
let moment = require('moment');

class ExternalApiCallsChart extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.props.dashboardActions.getMetrics("ExternalApiCalls")
            .then(()=>this.setState({isLoading: false}))
            .catch(()=>this.setState({isLoading: false}));
    }

    displayDate = (val) =>{
        let dt = moment(val);
        return dt.format("DD MMM");
    }

    getConfig = () =>{
        let { widgetData } = this.props.dashboard;
        let data = widgetData["ExternalApiCalls"];



        let config = {
            chart: {
                type: 'column',
                height: 250,
            },
            title:{
                text:''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: (data? Object.keys(data.series[0].data).map(x=>{
                    return this.displayDate(x);
                }):[])
            },
            series: (data? data.series.map(x=>{
                return {
                    data: Object.values(x.data),
                    name: x.name
                }
            }): []),
            yAxis: {
                title: {
                    text: null
                }
            }
        };

        console.log(config);
        return config;
    }

    render() {
        return (
            <div className="selector-container">
                <div className="panel-heading">
                    <h6 className="panel-title">Api calls chart</h6>
                </div>
                <div className="panel-body">
                    {this.state.isLoading
                        ? <FormLoader/>
                        : null}
                    <ReactHighcharts config={this.getConfig()}/>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {dashboard: state.dashboard}
}

function mapDispatchToProps(dispatch) {
    return {
        dashboardActions: bindActionCreators(dashboardActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalApiCallsChart)