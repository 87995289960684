import React, { Component } from 'react'
import MegaTable from "../../../components/common/MegaTable";
import * as apiCallActions from "../actions/apiCallActions";
import * as turvoApiActionTypes from '../constants/apiActionTypes';
import * as providerTypes from '../constants/providerTypes';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import getLabelByValue from "../../../utils/enumHelper";
import displayDateTime from "../../../utils/dateTimeHelper";
import ExpandableText from "../../../components/common/ExpandableText/ExpandableText";
import TableFilters from './TableFilters';
import { parse } from '../../../utils/querystring';

class ApiCallsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 10,
            selectedItem: {},
            isWorking: false,
            search: '',
            filter: {
                requestId: this.getSearchData('requestId')
            }
        };

        this.shipmentActionTypes = [
            turvoApiActionTypes.GetDistance,
            turvoApiActionTypes.GetLatLonByAddress,
            turvoApiActionTypes.GetMileageReport,
            turvoApiActionTypes.GetTimeZone
        ];
    }

    getSearchData = (name) => {
        let searchParams = parse(this.props.location.search);
        return searchParams[name];
    }

    componentDidMount() {
        this.reloadData();
    }

    getSystemSettings = () => {
        if (this.props.settings)
            return this.props.settings.system_settings || {};
        return {};
    };

    reloadData = (noLoader, filter) => {
        if (!noLoader) {
            this.setState({ isWorking: true });
        }
        this
            .props
            .actions
            .list({ page: this.state.page, pageSize: this.state.pageSize, search: this.state.search, ...this.state.filter })
            .then(() => this.setState({ isWorking: false }))
            .catch(() => this.setState({ isWorking: false }));
    };

    getTurvoResourceLink = (item) => {
        if (!item.turvoResourceId)
            return null;

        if (this.shipmentActionTypes.includes(item.actionType)) {
            return <a href={this.getSystemSettings().turvoBaseUrl + '/#/shipments/' + item.turvoResourceId}
                target="_blank">{item.turvoResourceId}</a>
        }
    };

    getData = () => {
        if (this.props.apiCalls && this.props.apiCalls.list) {
            return this.props.apiCalls.list.data;
        }
        return [];
    };

    render() {
        let columns = [
            {
                name: 'Date',
                id: 'dateTime',
                type: 'custom',
                width: '190',
                renderDisplay: d => <span>
                    {displayDateTime(d.createDate, "DD MMM YY h:mm:ss A")}
                </span>
            },
            {
                name: 'Service Name',
                id: 'serviceName',
                type: 'text'
            },
            {
                name: 'Action Type',
                id: 'actionType',
                type: 'custom',
                width: '100',
                renderDisplay: d => <span>
                    {getLabelByValue(turvoApiActionTypes, d.actionType)}
                </span>
            },
            {
                name: 'Provider',
                id: 'providerType',
                type: 'custom',
                width: '100',
                renderDisplay: d => <span>
                    {getLabelByValue(providerTypes, d.providerType)}
                </span>
            },
            {
                name: 'Elapsed',
                id: 'elapsedTime',
                type: 'custom',
                renderDisplay: d => <span>
                    {d.elapsedTime}
                </span>
            },
            {
                name: 'Parameters',
                id: 'parameters',
                type: 'custom',
                renderDisplay: d => <ExpandableText text={d.parameters} />
            },
            {
                name: 'Request',
                id: 'request',
                type: 'custom',
                renderDisplay: d => <ExpandableText text={d.request} />
            },
            {
                name: 'Response',
                id: 'resposnse',
                type: 'custom',
                renderDisplay: d => <ExpandableText text={d.response} />
            },
            {
                name: 'Error',
                id: 'error',
                type: 'text'
            },
            {
                name: 'Status',
                id: 'status',
                width: '150',
                type: 'custom',
                renderDisplay: d => <span>
                    {d.apiCallResult === 0 && <span><i className="fa fa-check color-green" />&nbsp;Success</span>}
                    {d.apiCallResult === 1 && <span><i className="fa fa-remove color-red" />&nbsp;Failed</span>}
                </span>
            }
        ];

        let apiCalls = this.props.apiCalls || {};

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4><span className="text-semibold">Api calls</span></h4>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat">
                    <MegaTable
                        editMode={false}
                        columns={columns}
                        onRefresh={() => {
                            this.reloadData();
                        }}
                        isWorking={this.state.isWorking}
                        summary={apiCalls.list
                            ? {
                                skip: apiCalls.list.skip,
                                take: apiCalls.list.take,
                                total: apiCalls.list.total,
                                totalPages: apiCalls.list.totalPages
                            }
                            : null}
                        page={this.state.page}
                        pageChanged={(page) => {
                            if (page < 1)
                                page = 1;
                            this.setState({
                                page: page
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        pageSizeChanged={(size) => {
                            this.setState({
                                pageSize: size
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        searchChanged={(search) => {
                            this.setState({
                                search: search
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        filterComponent={<TableFilters requestId={this.state.filter.requestId}
                            searchChanged={filter => {
                                console.log(filter);
                                this.setState({ filter }, () => {
                                    console.log(this.state);
                                    this.reloadData();
                                })
                            }} />}
                        data={this.getData()} />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        apiCalls: state.apiCall,
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(apiCallActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiCallsTable)