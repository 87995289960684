import React, {Component} from 'react';
import {Modal, Button} from "react-bootstrap";

export default class RedirectEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () =>{
        return {
            searchString: '',
            line1: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            lat: '',
            lon: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.show && !this.props.show){
            this.setState(this.getInitialState());
        }
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit redirect</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">SearchString</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({searchString: e.target.value})}
                                    value={this.state.searchString}/>
                            </div>
                        </div>
                    </form>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Line1</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({line1: e.target.value})}
                                    value={this.state.line1}/>
                            </div>
                        </div>
                    </form>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">City</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({city: e.target.value})}
                                    value={this.state.city}/>
                            </div>
                        </div>
                    </form>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">State</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({state: e.target.value})}
                                    value={this.state.state}/>
                            </div>
                        </div>
                    </form>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Zip</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({zip: e.target.value})}
                                    value={this.state.zip}/>
                            </div>
                        </div>
                    </form>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Country</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({country: e.target.value})}
                                    value={this.state.country}/>
                            </div>
                        </div>
                    </form>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Lat</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({lat: e.target.value})}
                                    value={this.state.lat}/>
                            </div>
                        </div>
                    </form>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Lon</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({lon: e.target.value})}
                                    value={this.state.lon}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="success"
                        onClick={() => {
                            this
                                .props
                                .onConfirm({...this.state});
                        }}>Save</Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
