import React, {Component} from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div>
                <div className="footer text-muted text-center">
                    © RPM {(new Date()).getFullYear()}
                </div>
            </div>
        )
    }
}
