let moment = require('moment');

export default function displayDateTime(value, format, utc){
    let formatTemplate = format || "DD MMM YY hh:mm A";
    let dateTime = moment.utc(value);
    if(dateTime){
        if(!utc)
            return dateTime.local().format(formatTemplate);
        else
            return dateTime.utc().format(formatTemplate);
    } else{
        return "Invalid date time";
    }
}