import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as notificationActions from '../../pages/notifications/actions/notificationActions';

const withErrorHandler = (WrappedComponent, axios) => {
    class Wrapper extends Component {
        state = {
            errorMessage: null,
            error: false
        }

        componentWillMount() {
            this.reqInterceptor = axios.interceptors.request.use(req => {
                if (this.props.user && this.props.user.access_token) {
                    req.headers.Authorization = `Bearer ${this.props.user.access_token}`;
                }

                this.setState({ error: null });
                return req;
            });

            this.resInterceptor = axios.interceptors.response.use(res => {
                if (res.data && res.data.hasOwnProperty('isSuccess') && !res.data.isSuccess) {
                    this.setState({ errorMessage: res.data.errorMessage, error: true });
                    return res;
                }
                return res;
            }, error => {
                if (!error || !error.response) {
                    this.setState({ errorMessage: 'Something went wrong', error: true });
                    return;
                }

                switch (+error.response.status) {
                    case 401:
                        this.setState({ errorMessage: 'Not Authorized', error: true });
                        break;
                    case 404:
                        this.setState({ errorMessage: 'Not Found', error: true });
                        break;
                    case 504:
                        this.setState({ errorMessage: 'Internal Server Error', error: true });
                        break;
                    case 500:
                        this.setState({ errorMessage: error.response.data.message ? error.response.data.message : 'Something went wrong', error: true });
                        break;
                    default:
                        this.setState({ errorMessage: 'Something went wrong', error: true });
                        break;
                }

                throw error;
            });
        }

        componentDidUpdate(prevProps, prevState) {
            if (this.state.error === true && prevState.error !== this.state.error) {
                this.props.notificationActions.add(this.state.errorMessage, "", "fail")
            }
        }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.resInterceptor);
        }

        handleClose = () => this.setState({ error: false, errorMessage: null });

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => {
        return {
            user: state.oidc.user,
            isLoadingUser: state.oidc.isLoadingUser
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            notificationActions: bindActionCreators(notificationActions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
}

export default withErrorHandler;