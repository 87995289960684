import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, MenuItem } from "react-bootstrap";
import CustomMenu from "./CustomMenu";
import CustomToggle from "./CustomToggle";
import { userManager } from 'rug-widgets';

class UserWidget extends Component {
    handleLogout = () => {
        userManager.signoutRedirect()
            .catch(function (error) {
                console.error('error while signing out user', error);
            });
    }

    render() {
        return (
            <div>
                <ul className="nav navbar-nav navbar-right">
                    <li>
                        {this.props.isLoadingUser
                            ? <a href=""><i className="fa fa-spinner fa-spin fa-fw position-right" /></a>
                            : <Dropdown
                                id="dropdown-custom-menu"
                                className="dropdown btn-group navbar-dropdown">
                                <CustomToggle bsRole="toggle">
                                    {this.props.user ? this.props.user.profile.name : ''}
                                    <i className="fa fa-caret-down position-right" aria-hidden="true"></i>
                                </CustomToggle>

                                <CustomMenu bsRole="menu">
                                    <MenuItem eventKey="1" onClick={(e) => this.handleLogout(e)}>
                                        <i className="fa fa-power-off" aria-hidden="true"></i>Logout</MenuItem>
                                </CustomMenu>
                            </Dropdown>
                        }
                    </li>
                </ul>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        isLoadingUser: state.oidc.isLoadingUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // actions: bindActionCreators(authActions, dispatch),
        // settingActions: bindActionCreators(settingsActions, dispatch),
        // notificationActions: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserWidget)