import * as actionTypes from '../constants/actionTypes';

export default(state = {
    widgetData: {}
}, action) => {
    switch (action.type) {
        case actionTypes.GET_METRICS:
            return {
                ...state,
                widgetData: {
                    ...state.widgetData,
                    [action.name]: action.payload
                }
            };
        default:
            return state;
    }
};