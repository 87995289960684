import * as actionTypes from "../constants/actionTypes";
import generate from "../../../utils/guid";

export function add(title, description, type) {
    return (dispatch) => {
        dispatch({type: actionTypes.ADD_NOTIFICATION, payload: {
            id: generate(),
            title: title,
            description: description,
            type: type
        }});
    };
}

export function remove(id){
    return (dispatch) => {
        dispatch({type: actionTypes.REMOVE_NOTIFICATION, payload: id});
    };
}