import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function list(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/geocodingRedirect/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.REDIRECT_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function clear() {
    return (dispatch) => {
        dispatch({type: actionTypes.REDIRECT_CLEAR});
    };
}

export function save(obj, callback) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/geocodingRedirect/save", obj)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function remove(id, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/geocodingRedirect/delete", {id: id})
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}