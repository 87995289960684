import { WebStorageStateStore } from 'oidc-client';

export const SPELLBAR_CONFIG_ENDPOINT_URL = (() => {
    if (process.env.REACT_APP_ENV === 'local') {
        return 'https://authmanagement.loadrpm.com/api/userspellbar';
    }

    if (process.env.REACT_APP_ENV === 'dev') {
        return 'https://authmanagement.loadrpm.com/api/userspellbar';
    }

    if (process.env.REACT_APP_ENV === 'prod') {
        return 'https://authmanagement.loadrpm.com/api/userspellbar';
    }
})();

const AUTH_CLIENT_ID = (() => {
    if (process.env.REACT_APP_ENV === 'local') {
        return 'GeocodingGatewayDev';
    }

    if (process.env.REACT_APP_ENV === 'dev') {
        return 'GeocodingGatewayDev';
    }

    if (process.env.REACT_APP_ENV === 'prod') {
        return 'GeocodingGateway';
    }
})();

export const AUTH_CONFIG = {
    client_id: AUTH_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
    response_type: 'token id_token',
    scope: 'openid profile',
    authority: 'https://auth.loadrpm.com',
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: localStorage })
};