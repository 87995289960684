import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function list(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/apiCall/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.API_CALL_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function clear() {
    return (dispatch) => {
        dispatch({type: actionTypes.API_CALL_CLEAR});
    };
}
export function listLogs(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/log/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.API_CALL_LOGS_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function clearLogs() {
    return (dispatch) => {
        dispatch({type: actionTypes.API_CALL_LOGS_CLEAR});
    };
}