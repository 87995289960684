import axios from '../axios/axios-api';

class httpHandler {
    get = (url) => {
        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    maxRedirects: 0,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    post = (url, params, opt) => {
        return new Promise((resolve, reject) => {
            axios
                .post(url, params, opt)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
}

export default new httpHandler();