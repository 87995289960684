import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function getMetrics(metricName) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/metrics/generate", {name: metricName})
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.GET_METRICS, payload: response.data, name: metricName});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}