import React, { Component } from 'react'
import MegaTable from "../../../components/common/MegaTable";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as redirectActions from "../actions/redirectActions";
import * as notificationActions from "../../notifications/actions/notificationActions";
import RedirectEditModal from "./modals/RedirectEditModal";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";

class RedirectsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 10,
            selectedItem: {},
            isWorking: false,
            search: ''
        };
    }

    componentDidMount() {
        this.reloadData();
    }

    reloadData = (noLoader, filter) => {
        if (!noLoader) {
            this.setState({ isWorking: true });
        }
        this
            .props
            .actions
            .list({ page: this.state.page, pageSize: this.state.pageSize, search: this.state.search })
            .then(() => this.setState({ isWorking: false }))
            .catch(() => this.setState({ isWorking: false }));
    };

    getData = () => {
        if (this.props.redirects && this.props.redirects.list) {
            return this.props.redirects.list.data;
        }
        return [];
    };

    render() {
        let columns = [
            {
                name: 'SearchString',
                id: 'searchString',
                type: 'text'
            },
            {
                name: 'Line1',
                id: 'line1',
                type: 'text'
            },
            {
                name: 'City',
                id: 'city',
                type: 'text'
            },
            {
                name: 'State',
                id: 'state',
                type: 'text'
            },
            {
                name: 'Zip',
                id: 'zip',
                type: 'text'
            },
            {
                name: 'Country',
                id: 'country',
                type: 'text'
            },
            {
                name: 'Lat',
                id: 'lat',
                type: 'text'
            },
            {
                name: 'Lon',
                id: 'lon',
                type: 'text'
            },
            {
                name: 'Actions',
                id: 'Actions',
                type: 'custom',
                width: '100',
                renderDisplay: d => <div>
                    <a className="btn btn-xxs bg-danger" onClick={(e) => {
                        e.preventDefault();

                        this.setState({ showDeleteConfirm: true, selectedItem: d });
                    }}>Delete</a>
                </div>
            }
        ];

        let redirects = this.props.redirects || {};

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4><span className="text-semibold">Geocoding redirects</span></h4>
                        </div>

                        <div className="heading-elements">
                            <div className="heading-btn-group">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ isAddRedirectModalOpen: true });

                                }} className="btn bg-blue has-text">
                                    <i className="fa fa-plus"></i>
                                    <span>Add</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat">
                    <MegaTable
                        editMode={false}
                        columns={columns}
                        onRefresh={() => {
                            this.reloadData();
                        }}
                        isWorking={this.state.isWorking}
                        summary={redirects.list
                            ? {
                                skip: redirects.list.skip,
                                take: redirects.list.take,
                                total: redirects.list.total,
                                totalPages: redirects.list.totalPages
                            }
                            : null}
                        page={this.state.page}
                        pageChanged={(page) => {
                            if (page < 1)
                                page = 1;
                            this.setState({
                                page: page
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        pageSizeChanged={(size) => {
                            this.setState({
                                pageSize: size
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        searchChanged={(search) => {
                            this.setState({
                                search: search
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        data={this.getData()} />
                </div>
                <RedirectEditModal
                    onClose={() => this.setState({ isAddRedirectModalOpen: false })}
                    onConfirm={(obj) => {
                        debugger;
                        console.log(obj);
                        this.setState({ isWorking: true });

                        this.props.actions.save(obj).then(() => {
                            this
                                .props
                                .notification
                                .add("Redirect saved successfully", "", "success");
                            this.setState({ isWorking: false, isAddRedirectModalOpen: false }, () => {
                                this.reloadData();
                            });
                        }).catch(() => {
                            this
                                .props
                                .notification
                                .add("Redirect save failed", "", "fail");
                            this.setState({ isWorking: false });
                        });
                    }}
                    show={this.state.isAddRedirectModalOpen} />
                <DeleteConfirmationModal
                    title={"Delete redirect confirmation"}
                    description={"Are you sure you want to delete redirect?"}
                    onClose={() => this.setState({ showDeleteConfirm: false })}
                    onConfirm={() => {
                        this
                            .props
                            .actions
                            .remove(this.state.selectedItem.id, () => {
                                this.reloadData();
                                this.setState({ showDeleteConfirm: false });
                            })
                    }}
                    show={this.state.showDeleteConfirm} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        redirects: state.redirect,
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(redirectActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectsTable)