import React from "react";
import ExpandableTextModal from "./ExpandableTextModal";

export default class ExpandableText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };

        this.trimTreshhold = 30;
    }

    getTrimmedText = (text) => {
        if (!text)
            return '';
        if (text.length < this.trimTreshhold)
            return text;

        return text.substr(0, this.trimTreshhold) + "...";
    }

    render() {
        if (!this.props.text)
            return null;

        return (
            <span>
                {this.props.text.length > this.trimTreshhold ?
                    <span>{this.getTrimmedText(this.props.text)}&nbsp;<a onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            showModal: !this.state.showModal
                        });
                    }}>show</a>
                <ExpandableTextModal show={this.state.showModal} text={this.props.text} onClose={() => {
                    this.setState({showModal: false});
                }}/>
                </span> : <span>{this.props.text}</span>}
            </span>
        );
    }
}