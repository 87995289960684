import React, { Component } from 'react';
import {debounce} from "throttle-debounce";
import moment from 'moment';
import DateRangeSelector from '../../../components/common/DateRangeSelector';
import DropdownSelector from '../../../components/common/DropdownSelector';

const actionTypes = [
    { id: 0, value: 'GetLatLonByAddress' },
    { id: 1, value: 'GetTimeZone' },
    { id: 2, value: 'GetDistance' },
    { id: 3, value: 'GetMileageReport' },
    { id: 4, value: 'BatchGeocode' },
    { id: 5, value: 'Autocomplete' }
];

const statusTypes = [
    { id: 0, value: 'Success'},
    { id: 1, value: 'Failed'}
];

class TableFilters extends  Component {
    constructor(props) {
        super(props);

        this.state = {
            from: moment.utc("0001-01-01"),
            to: moment.utc("0001-01-01"),
            actions: [],
            statuses: [],
            requestId: this.props.requestId
        }
    }

    changeDatesValues = (from, to) => {

        if (from <= to) {
            this.setState({
                to,
                from
            }, () => this.props.searchChanged(this.state))
        }
    };

    render() {
        return(
            <div className="megatable-filters-header">
                <div>
                    <label>
                        <span className="label-indent-right">Search:</span>
                        <input
                            value={this.state.search}
                            onChange={(e) => {
                                this.setState({
                                    search: e.target.value
                                }, () => {
                                    debounce(500, this.props.searchChanged(this.state));
                                })
                            }}
                            type="search"
                            placeholder="Type to filter..."/>
                    </label>
                </div>
                <DateRangeSelector
                    title="Dates"
                    selectedFrom={this.state.from}
                    selectedTo={this.state.to}
                    onDatesChanged={(from, to) => this.changeDatesValues(from, to)}
                />
                <DropdownSelector
                    title="Action type"
                    items={actionTypes}
                    itemId={el => el.id}
                    itemRender={el => el.value}
                    onChange={actions => this.setState({ actions }, () => this.props.searchChanged(this.state))}
                    selectedOptions={this.state.actions}
                />
                <DropdownSelector
                    title="Status"
                    items={statusTypes}
                    itemId={el => el.id}
                    itemRender={el => el.value}
                    onChange={statuses => this.setState({ statuses }, () => this.props.searchChanged(this.state))}
                    selectedOptions={this.state.statuses}
                />
                <div>
                    <label>
                        <span className="label-indent-right">Request id:</span>
                        <input
                            value={this.state.requestId}
                            onChange={(e) => {
                                this.setState({
                                    requestId: e.target.value
                                }, () => {
                                    debounce(500, this.props.searchChanged(this.state));
                                })
                            }}
                            type="search"
                            placeholder="Request id..."/>
                    </label>
                </div>
            </div>
        )
    }
}

export { TableFilters as default };