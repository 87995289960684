import { applyMiddleware, combineReducers, createStore, compose } from 'redux';

import thunk from 'redux-thunk';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducer as oidcReducer } from 'redux-oidc';

import settings from "../../pages/settings/reducers/settings";
import dashboard from "../../pages/dashboard/reducers/dashboard";
import notification from "../../pages/notifications/reducers/notification";
import apiCall from "../../pages/apiCall/reducers/apiCall";
import redirect from "../../pages/redirect/reducers/redirect";

export const history = createBrowserHistory();

export const configureStore = () => {
    const reducers = {
        router: connectRouter(history),
        oidc: oidcReducer,
        notification,
        settings,
        dashboard,
        apiCall,
        redirect
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers
    });

    let composeEnhancers = null;

    if (process.env.NODE_ENV === 'production') {
        composeEnhancers = compose;
    } else {
        composeEnhancers =
            typeof window === 'object' &&
                window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
                : compose;
    }

    const enhancer = composeEnhancers(
        applyMiddleware(...middleware)
    );

    return createStore(rootReducer, enhancer);
}