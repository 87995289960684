import React, { Component } from 'react'
import { debounce } from 'throttle-debounce';
import FormLoader from './FormLoader';

export default class MegaTable extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
        this.buttonStyle = {
            marginRight: '10px'
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.data });

        if (this.props.showNewItemRow !== nextProps.showNewItemRow) {
            this.setState({ newRow: {} });
        }
    }

    getNewRow = () => {
        let newRow = {};
        this
            .props
            .columns
            .forEach((x) => {
                newRow[x.id] = "";
            });
        return newRow;
    };

    getInitialState() {
        return {
            newRow: {},
            data: this.props.data || [],
            exportFormat: 'CSV',
            showModal: false,
            pageSize: 10,
            search: ''
        };
    }

    pageClick = (e, page) => {
        e.preventDefault();
        this
            .props
            .pageChanged(page);
    }

    renderEditColumn = (col, item) => {
        let colId = col.id;
        const tdStyle = {
            padding: '0px 2px'
        };
        if (col.width)
            tdStyle.width = col.width + 'px';
        if (col.type === 'number') {
            return <td style={tdStyle} key={col.id}>
                <input
                    type="number"
                    pattern="[0-9]+"
                    step="1"
                    value={item[colId]}
                    onChange={(e) => {
                        item[colId] = e.target.value;
                        this.forceUpdate();
                    }}
                    className="form-control" />
            </td>;
        } else if (col.type === 'float') {
            return <td style={tdStyle} key={col.id}>
                <input
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    step="0.01"
                    value={item[colId]}
                    onChange={(e) => {
                        item[colId] = e.target.value;
                        this.forceUpdate();
                    }}
                    className="form-control" />
            </td>;
        } else if (col.type === 'custom') {
            return <td style={tdStyle} key={col.id}>
                {col.renderEdit
                    ? col.renderEdit(item)
                    : ""}
            </td>;
        } else {
            return <td style={tdStyle} key={col.id}>
                <input
                    type='text'
                    value={item[colId]}
                    onChange={(e) => {
                        item[colId] = e.target.value;
                        this.forceUpdate();
                    }}
                    className="form-control" />
            </td>;
        }
    };

    handleRemove = (item) => {
        this.props.handleRemove(item);
    }

    renderColumn = (col, item) => {
        if (col.isHidden) {
            return null;
        }
        if (item.isEditMode && this.props.editMode) {
            return this.renderEditColumn(col, item);
        } else {
            if (col.type === 'custom') {
                return <td key={col.id}>{col.renderDisplay
                    ? col.renderDisplay(item)
                    : ""}</td>;
            } else {
                return <td key={col.id}>{item[col.id]}</td>;
            }

        }
    };

    tableLoader = () => {
        return (<FormLoader />);
    }

    renderHeaderRow = () => {
        let columns = this
            .props
            .columns
            .map((col) => {
                if (col.isHidden) {
                    return null;
                }
                let headerThStyle = {};
                if (col.width) {
                    headerThStyle.width = col.width + "px";
                }
                return <th key={col.id} style={headerThStyle}>{col.name}</th>;
            });

        if (this.props.editMode)
            columns.push(<th key="action" />);

        return (<tr>
            {columns}
        </tr>);
    }

    renderNewItemRow = () => {
        let columns = this
            .props
            .columns
            .map((col) => {
                if (col.isHidden) {
                    return null;
                }
                let headerThStyle = {
                    padding: '0px 2px',
                    paddingTop: '2px',
                    paddingBottom: '2px'
                };
                if (col.width) {
                    headerThStyle.width = col.width + "px";
                }
                return <td key={col.id} style={headerThStyle}>
                    <input type="text" onChange={(e) => {

                        let newRow = this.state.newRow;
                        if (Object.keys(newRow).length === 0)
                            newRow = this.getNewRow();

                        newRow[col.id] = e.target.value;
                        this.setState({ newRow: newRow });

                    }} className="form-control megatable-filter-input" />
                </td>;
            });

        if (this.props.editMode)
            columns.push(<td key="action">
                <a
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.onNewItem(this.state.newRow);
                    }}>
                    <i className="fa fa-check" aria-hidden="true" />
                </a>
            </td>);

        return (<tr>
            {columns}
        </tr>);
    }

    renderFilterRow = () => {
        let columns = this
            .props
            .columns
            .map((col) => {
                if (col.isHidden) {
                    return null;
                }
                let headerThStyle = {
                    padding: '0px 2px',
                    paddingTop: '2px',
                    paddingBottom: '2px'
                };
                if (col.width) {
                    headerThStyle.width = col.width + "px";
                }
                return <th key={col.id} style={headerThStyle}>
                    <input type="text" onChange={(e) => {
                        this.props.onFilterChange(col.id, e.target.value);
                    }} className="form-control megatable-filter-input" />
                </th>;
            });

        if (this.props.editMode)
            columns.push(<th key="action" />);

        return (<tr>
            {columns}
        </tr>);
    }

    renderRow = (item, i) => {
        const actionTdStyle = {
            width: '80px'
        };
        const actionLinkStyle = {
            marginLeft: '10px'
        };

        return <tr key={item.id || i}>
            {this
                .props
                .columns
                .map((col) => {
                    return this.renderColumn(col, item);
                })}
            {this.props.editMode
                ? (item.isEditMode
                    ? <td style={actionTdStyle}>
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                item.isEditMode = false;
                                this.forceUpdate();
                                let { isEditMode, ...itemModel } = item; //remove isEditMode
                                this
                                    .props
                                    .onChange(itemModel)
                            }}>
                            <i className="fa fa-check" aria-hidden="true" />
                        </a>
                    </td>
                    : <td style={actionTdStyle}>
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                item.isEditMode = true;
                                this.forceUpdate();
                            }}>
                            <i className="fa fa-pencil-square-o" aria-hidden="true" />
                        </a>
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleRemove(item);
                            }}
                            style={actionLinkStyle}>
                            <i className="fa fa-times" aria-hidden="true" />
                        </a>
                    </td>)
                : null}

        </tr>;
    };

    getPagesCount = () => {
        if (this.props.summary) {
            return this.props.summary.totalPages;
        }
        return 0;
    }

    handleNextPageClick = (e) => {
        e.preventDefault();
        if (this.props.page !== this.getPagesCount()) {
            this
                .props
                .pageChanged(this.props.page + 1);
        }
    }

    handlePrevPageClick = (e) => {
        e.preventDefault();
        if (this.props.page !== 1) {
            this
                .props
                .pageChanged(this.props.page - 1);
        }
    }

    handleRefresh = () => {
        if (this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    renderSimplePaginationLinks = () => {
        return (Array.from(Array(this.props.summary.totalPages), (x, i) => i).map((p) => {
            let page = p + 1;
            return (
                <a
                    key={p}
                    onClick={(e) => this.pageClick(e, page)}
                    className={"paginate_button " + (this.props.page === page
                        ? "current"
                        : "")}>{page}</a>
            );
        }))
    }

    renderSmartPaginationLinks = () => {
        let links = [];
        if (this.props.page > 4) {
            links.push(<a
                key={1}
                onClick={(e) => this.pageClick(e, 1)}
                className={"paginate_button"}>1</a>);
            links.push(<span>...</span>);
        }

        for (let i = this.props.page - 4; i < this.props.page - 1; i++) { // render 3 pages before
            let page = i + 1;
            if (page < 1)
                continue;
            links.push(<a
                key={page}
                onClick={(e) => this.pageClick(e, page)}
                className={"paginate_button"}>{page}</a>);
        }

        links.push(<a
            key={this.props.page}
            onClick={(e) => this.pageClick(e, this.props.page)}
            className={"paginate_button current"}>{this.props.page}</a>);

        for (let i = this.props.page; i < this.props.page + 3; i++) { // render 3 pages after
            let page = i + 1;
            if (page > this.props.summary.totalPages)
                continue;
            links.push(<a
                key={page}
                onClick={(e) => this.pageClick(e, page)}
                className={"paginate_button"}>{page}</a>);
        }

        if (this.props.page < (this.props.summary.totalPages - 3)) {
            links.push(<span key="dots">...</span>);
            links.push(<a
                key={this.props.summary.totalPages}
                onClick={(e) => this.pageClick(e, this.props.summary.totalPages)}
                className={"paginate_button"}>{this.props.summary.totalPages}</a>);
        }

        return links;
    }

    renderPagination = () => {
        let paginationLinks = null;

        if (this.props.summary) {
            if (this.props.summary.totalPages < 10) { // Simple pagination
                paginationLinks = this.renderSimplePaginationLinks();
            } else {
                paginationLinks = this.renderSmartPaginationLinks();
            }
        }

        return (
            <div
                className="dataTables_paginate paging_simple_numbers"
                id="DataTables_Table_0_paginate">
                <a
                    onClick={this.handlePrevPageClick}
                    className={"paginate_button previous " + (this.props.page === 1
                        ? "disabled"
                        : "")}>←</a>
                <span>
                    {this.props.summary
                        ? paginationLinks
                        : <a className="paginate_button current">1</a>}
                </span>
                <a
                    onClick={this.handleNextPageClick}
                    className={"paginate_button next " + (this.props.page === this.getPagesCount()
                        ? "disabled"
                        : "")}>→</a>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="table-responsive">
                    {!this.props.hideHeader ? <div className="datatable-header">
                        <div className="dataTables_filter">
                            {this.props.filterComponent ||
                                (
                                    <label>
                                        <span className="label-indent-right">Filter:</span>
                                        <input
                                            value={this.state.search}
                                            onChange={(e) => {
                                                this.setState({
                                                    search: e.target.value
                                                }, () => {
                                                    debounce(500, this.props.searchChanged(this.state.search));
                                                })
                                            }}
                                            type="search"
                                            placeholder="Type to filter..." />
                                    </label>
                                )}
                        </div>
                        <div className="table-refresh-button">
                            <a onClick={(e) => {
                                e.preventDefault();
                                this.handleRefresh();
                            }}>
                                <i className="fa fa-refresh" />
                            </a>
                        </div>
                        <div className="dataTables_length">
                            <label>
                                <span>Show:</span>
                                <select
                                    value={this.state.pageSize}
                                    onChange={(e) => {
                                        this.setState({
                                            pageSize: e.target.value
                                        }, () => {
                                            this
                                                .props
                                                .pageSizeChanged(this.state.pageSize);
                                        });
                                    }}
                                    className="select2-hidden-accessible">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </label>
                        </div>
                    </div> : null}


                    <div className="relative">
                        {this.props.isWorking
                            ? this.tableLoader()
                            : null}
                        <table className={"table " + this.props.className}>

                            <thead>
                                {this.renderHeaderRow()}
                                {this.props.showFilter ? this.renderFilterRow() : null}
                            </thead>
                            <tbody>
                                {this.props.showNewItemRow ? this.renderNewItemRow() : null}
                                {this
                                    .state
                                    .data
                                    .map((item, i) => {
                                        return this.renderRow(item, i)
                                    })}
                            </tbody>
                        </table>
                    </div>

                    {!this.props.hideFooter ? <div className="datatable-footer">
                        <div
                            className="dataTables_info"
                            id="DataTables_Table_0_info"
                            role="status"
                            aria-live="polite">
                            {this.props.summary
                                ? <span>Showing {this.props.summary.skip + " "}
                                    to {this.props.summary.take + " "}
                                    of {this.props.summary.total + " "}
                                    entries</span>
                                : <span>Showing 0 to 0 of 0 entries</span>}

                        </div>
                        {this.renderPagination()}
                    </div> : null}


                </div>
            </div>
        )
    }
}
