import React, { Component } from 'react'
import ReactDOM from "react-dom";

export default class CustomMenu extends Component {
    constructor(props, context) {
        super(props, context);
    
        this.onChange = e => this.setState({ value: e.target.value });
    
        this.state = { value: '' };
      }
    
      focusNext() {
        const input = ReactDOM.findDOMNode(this.input);
    
        if (input) {
          input.focus();
        }
      }
    
      render() {
        const { children } = this.props;
        const { value } = this.state;
    
        return (
          <ul className="list-unstyled dropdown-menu" style={{ padding: '' }}>
              {React.Children.toArray(children).filter(child => (
                !value.trim() || child.props.children.indexOf(value) !== -1
              ))}
            </ul>
        );
      }
}
