import * as actionTypes from '../constants/actionTypes';

export default(state = {}, action) => {
    switch (action.type) {
        case actionTypes.API_CALL_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.API_CALL_CLEAR:
            return {
                ...state,
                list: null
            };
        case actionTypes.API_CALL_LOGS_LIST:
            return {
                ...state,
                logs: action.payload
            };
        case actionTypes.API_CALL_LOGS_CLEAR:
            return {
                ...state,
                logs: null
            };
        default:
            return state;
    }
};