import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import RedirectsTable from "./components/RedirectsTable";

export default class Redirects extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/redirect" component={RedirectsTable}/>
            </Switch>
        )
    }
}